<template>
  <v-navigation-drawer
    id="core-navigation-drawer"
    v-model="drawer"
    :dark_="barColor !== 'rgba(228, 226, 226, 1), rgba(255, 255, 255, 0.7)'"
    :mini-variant.sync="internalValue"
    :right="$vuetify.rtl"
    :src_="barImage"
    mobile-break-point="960"
    app
    mini-variant-width="80"
    width="240"
    v-bind="$attrs"
    class="elevation-6"
  >
    <common-trs-btn
      v-if="value"
      class="expand-icon"
      type="tertiary"
      small
      fab
      color="white"
      @click="toggleDrawer"
    >
      <v-icon
        class="icon-right-nav"
        color="primary"
      />
    </common-trs-btn>

    <common-trs-btn
      v-else
      class="expand-icon"
      type="tertiary"
      small
      fab
      icon
      @click="toggleDrawer"
    >
      <v-icon
        class="icon-left-nav"
        color="primary"
      />
    </common-trs-btn>

    <div
      v-if="false"
      class="d-flex mb-2 d-flex align-center justify-center"
      :class="{ 'flex-column': value }"
    >
      <div class="px-2">
        <v-tooltip
          top
        >
          <template v-slot:activator="{ attrs, on }">
            <common-trs-btn
              type="tertiary"
              color="trsText--text"
              icon
              :to="{ name: 'Logout' }"
              v-bind="attrs"
              v-on="on"
            >
              <v-icon>mdi-logout</v-icon>
            </common-trs-btn>
          </template>
          <span>Log out</span>
        </v-tooltip>
      </div>
    </div>

    <!-- <v-divider
      v-if="!isCaptableAdmin"
      class="my-2"
    /> -->

    <div
      v-if="!isCaptableAdmin && currentOrganization && currentOrganization.name"
      class="py-3"
    >
      <div class="trsText--text text-caption font-weight-light text-center">
        Representing
      </div>
      <div class="primary--text text-body-1 text-center">
        {{ currentOrganization.name }}
      </div>
    </div>

    <v-divider class="mb-2" />

    <v-list
      class="main-nav-list pa-0"
      expand
      nav
    >
      <!-- Style cascading bug  -->
      <!-- https://github.com/vuetifyjs/vuetify/pull/8574 -->
      <div />

      <template v-for="(item, i) in computedItems">
        <common-trs-nav-item-group
          v-if="item.children"
          :key="`group-${i}`"
          :item="item"
        >
          <!--  -->
        </common-trs-nav-item-group>

        <common-trs-nav-item
          v-else
          :key="`item-${i}`"
          :item="item"
        />
      </template>
    </v-list>
    <v-spacer />

    <common-app-logo
      max-width="100"
      max-height="40"
      class="mb-0"
      contain
    />
  </v-navigation-drawer>
</template>

<script>
  // Utilities
  import {
    mapState,
    mapMutations,
  } from 'vuex'
  import { ISSUER_ROLE } from '@/constants'

  export default {
    name: 'DashboardCoreDrawer',

    props: {
      value: {
        type: Boolean,
        default: false,
      },
      disableAutoMiniVariant: {
        type: Boolean,
        default: false,
      },
    },

    data: () => ({
      captableItems: [
        {
          icon: 'mdi-account-supervisor',
          title: 'Dashboard',
          to: '/holders-dashboard',
        },
        {
          icon: 'mdi-calendar-month',
          title: 'Cap Table',
          to: '/captable',
          pemRolesAllowed: [ISSUER_ROLE.SUPERADMIN, ISSUER_ROLE.CAPTABLE_ADMIN, ISSUER_ROLE.CAPTABLE_EDITOR, ISSUER_ROLE.CAPTABLE_VIEWER],
        },
        {
          icon: 'mdi-shield-lock-outline',
          title: 'Securities',
          to: '/securities',
          pemRolesAllowed: [ISSUER_ROLE.SUPERADMIN, ISSUER_ROLE.CAPTABLE_ADMIN, ISSUER_ROLE.CAPTABLE_EDITOR],
        },
        {
          icon: 'mdi-graph',
          title: 'Scenarios & Waterfall Analysis',
          to: '/scenarios',
          pemRolesAllowed: [ISSUER_ROLE.SUPERADMIN, ISSUER_ROLE.CAPTABLE_ADMIN, ISSUER_ROLE.CAPTABLE_EDITOR],
        },
        {
          icon: 'mdi-account-supervisor',
          title: 'Holders',
          to: '/manage-holders',
          pemRolesAllowed: [ISSUER_ROLE.SUPERADMIN, ISSUER_ROLE.CAPTABLE_ADMIN],
        },
        {
          icon: 'mdi-account-wrench',
          title: 'Service Requests',
          to: '/service-requests',
          pemRolesAllowed: [ISSUER_ROLE.SUPERADMIN, ISSUER_ROLE.CAPTABLE_ADMIN, ISSUER_ROLE.CAPTABLE_EDITOR, ISSUER_ROLE.CAPTABLE_VIEWER],
        },
      ],
      captableAdminItems: [
        {
          icon: 'mdi-account-supervisor',
          title: 'Users',
          to: '/captable-users',
        },
      ],
      captableHolderItems: [
        {
          icon: 'mdi-account-supervisor',
          title: 'Dashboard',
          to: '/holders-dashboard',
        },
      ],
    }),

    computed: {
      ...mapState('theme', ['barColor', 'barImage']),
      drawer: {
        get () {
          return this.$store.state.app.drawer
        },
        set (val) {
          this.$store.commit('app/SET_DRAWER', val)
        },
      },
      user () {
        return this.$store.getters['auth/user']
      },
      computedItems () {
        return this.filterNavItems
      },
      profile () {
        return {
          // avatar: true,
          avatarText: `${this.currentUser.first_name} ${this.currentUser.last_name}`,
          group: '/my-profile',
          title: `${this.currentUser.first_name} ${this.currentUser.last_name}`,
          children: [],
        }
      },
      internalValue: {
        get () {
          return this.value
        },
        set (val) {
          this.$emit('input', val)
        },
      },
      currentUser () {
        return this.$store.getters['auth/user']
      },
      userRole () {
        return this.$store.getters['auth/roles']
      },
      currentOrganization () {
        return this.$store.getters['auth/currentOrganization']
      },
      isCaptableAdmin () {
        return this.$store.getters['auth/isCaptableAdmin']
      },
      source () {
        return this.$store.getters['auth/source']
      },
      filterNavItems () {
        if (this.isCaptableAdmin) {
          return this.captableAdminItems
        } else if (this.userRole.includes(this.CONSTS.USER_ROLES.HOLDER)) {
          return this.captableHolderItems
        } else if (this.source === this.CONSTS.CAPTABLE_SOURCES_TYPES.V2) {
          const navItems = JSON.parse(JSON.stringify(this.captableItems))
          if (!this.userRole.includes(this.CONSTS.USER_ROLES.CAPTABLE_ADMIN) && !this.userRole.includes(this.CONSTS.USER_ROLES.CAPTABLE_SUPER_ADMIN)) {
            const index = navItems.findIndex(item => item.title === 'Holders')
            navItems.splice(index, 1)
          }
          return navItems
        } else {
          const navItems = JSON.parse(JSON.stringify(this.captableItems))
          const holderIndex = navItems.findIndex(item => item.title === 'Holders')
          navItems.splice(holderIndex, 1)
          return navItems
        }
      },
    },

    watch: {
      '$vuetify.breakpoint.smAndDown' (val) {
        this.$emit('update:value', !val)
      },
    },

    methods: {
      toggleDrawer () {
        if (this.disableAutoMiniVariant) {
          this.setDrawer(!this.drawer)
        } else {
          this.$vuetify.breakpoint.mdAndDown
            ? this.setDrawer(!this.drawer)
            : this.$emit('input', !this.value)
        }
      },
      ...mapMutations('app', {
        setDrawer: 'SET_DRAWER',
      }),
      mapItem (item) {
        return {
          ...item,
          children: item.children ? item.children.map(this.mapItem) : undefined,
          title: item.title,
        }
      },
      showAlert () {
        if (this.$vuetify.breakpoint.mdAndDown) {
          this.setDrawer(!this.drawer)
        }
        this.$store.commit('app/setShowAlertModal', true)
      },
    },
  }
</script>

<style lang="scss" scoped>
  .expand-icon {
    width: 32px;
    height: 32px;
    background-color: var(--v-accent-lighten5);
    position: absolute;
    right: -15px;
    top: 26px;
    z-index: 99999;
  }
</style>
